import React from "react";
import { Link } from "gatsby";

const SectionNav = ({ data, base, currentUri }) => {

  const sectionNavLinks = [ {name: "Overview", href: `${base}`} ];
  if(data.entry.relatedAreasOfExpertise.length > 0 || data.entry.relatedAreasOfExpertiseFocusAreas.length > 0) sectionNavLinks.push({name: "Areas of expertise", href: `${base}/areas-of-expertise`});
  if(data.entry.relatedFocusArea.length > 0) sectionNavLinks.push({name: "Related focus areas", href: `${base}/focus-areas`});
  if(data.relatedFocusAreaProjects.nodes.length > 0 || data.relatedAreaOfExpertiseProjects.nodes.length > 0) sectionNavLinks.push({name: "All projects", href: `${base}/projects`});
  if(data.entry.relatedTeamMembers.length > 0) sectionNavLinks.push({name: "Team", href: `${base}/team`});

  return (
    <section className="section-nav">
      <div className="shell">
        <nav className="section__nav">
          <ul>
            {sectionNavLinks.map((item, i) => (
              <li key={`section-nav-${i}`} className={item.href === currentUri ? "is-active" : ""}>
                <Link to={item.href}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default SectionNav;
