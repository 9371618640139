import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import SectionNav from "../components/newComponents/sectionNav";
import SectionHead from "../components/newComponents/sectionHead";
import Card from "../components/newComponents/card";

export const query = graphql`
  
  query WhatWeDoFocuAreasEntryQuery($slug: String!) {
    entry: craftWhatWeDoWhatWeDoEntry(slug: { eq: $slug }) {
      title
      relatedAreasOfExpertiseFocusAreas {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          id
        }
      }
      relatedAreasOfExpertise {
        ... on Craft_areaOfExpertise_areaOfExpertise_Entry {
          id
        }
      }
      relatedProjects {
        ... on Craft_projects_projects_Entry {
          id
        }
      }
      relatedFocusArea {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          displayTitle
          summary
          uri
          featureImage {
           ...AssetFragment
          }
        }
      }
      relatedTeamMembers {
        ... on Craft_staff_staff_Entry {
          slug
        }
      }
    }

    relatedFocusAreaProjects: allCraftProjectsProjectsEntry(filter: {relatedFocusArea: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }

    relatedAreaOfExpertiseProjects: allCraftProjectsProjectsEntry(filter: {relatedAreaOfExpertise: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }
  }
`;

const WhatWeDoFocuAreasEntry = ({ data, pageContext }) => (
  <Layout pageContext={pageContext}>
    <Seo title={data.entry.title} />
    <div className="main">
      <SectionNav data={data} base={`/what-we-do/${pageContext.slug}`} currentUri={`/what-we-do/${pageContext.slug}/focus-areas`} />
      <section className="section-overview" style={{paddingBottom: 0}}>
          <div className="shell">
          <SectionHead title={data.entry.title} subtitle={"Related focus areas"}/>
            </div>
        </section>
      <section className="section-listing section-listing--secondary js-section-listing" style={{paddingTop: 0}}>
        <div className="shell">
          
          <div className="boxes swiper-container js-slider-boxes-projects">
            <ul>
              {data.entry.relatedFocusArea.length > 0 ?
                data.entry.relatedFocusArea.map((focusArea, i) => (
                  <Card key={`what-we-do-focus-areas-${i}`} image={focusArea.featureImage[0]} title={focusArea.displayTitle} subtitle={focusArea.summary} href={`/${focusArea.uri}`} color={2}/>
                ))
                :
                <li className="box">No content found.</li>
              }
            </ul>
          </div>
        </div>
      </section>
    </div>
  </Layout> 
);

export default WhatWeDoFocuAreasEntry;